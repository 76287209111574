import { TransactionOptions, TransactionStatus, useContractFunction } from '@usedapp/core'
import { Contract, ContractInterface, providers, utils } from 'ethers'
import { useEffect, useMemo } from 'react'

import {
  CAPITAL_CALLS_VAULT_ABI,
  CAPITAL_CALLS_VAULT_INTERFACE,
  ERC20_ABI,
  ERC20_INTERFACE,
  FIXED_INTERESTED_ONLY_LOANS_ABI,
  FIXED_INTERESTED_ONLY_LOANS_INTERFACE,
  MULTI_WITHDRAWAL_CONTROLLER_ABI,
  MULTI_WITHDRAWAL_CONTROLLER_INTERFACE,
  STRUCTURED_ASSET_VAULT_ABI,
  STRUCTURED_ASSET_VAULT_FACTORY_ABI,
  STRUCTURED_ASSET_VAULT_FACTORY_INTERFACE,
  STRUCTURED_ASSET_VAULT_INTERFACE,
  STRUCTURED_PORTFOLIO_ABI,
  STRUCTURED_PORTFOLIO_FACTORY_ABI,
  STRUCTURED_PORTFOLIO_FACTORY_INTERFACE,
  STRUCTURED_PORTFOLIO_INTERFACE,
  TRANCHE_VAULT_ABI,
  TRANCHE_VAULT_INTERFACE,
} from 'constants/abis'
import { ABI_FUNCTIONS } from 'types/abi'
import { useNotifications } from 'providers/NotificationsProvider'

function createUseContractFunction<T>(abi: ContractInterface) {
  return (
    address: string,
    functionName: T extends readonly string[] ? ABI_FUNCTIONS<T> : string,
    options?: TransactionOptions,
  ): {
    send: (...args: unknown[]) => Promise<providers.TransactionReceipt | undefined>
    state: TransactionStatus
    events: utils.LogDescription[] | undefined
    resetState: () => void
  } => {
    const contract = useMemo(() => new Contract(address, abi), [address])
    const contractFunction = useContractFunction(contract, functionName, { gasLimitBufferPercentage: 10, ...options })
    const { addNotification } = useNotifications()

    useEffect(() => {
      if (contractFunction?.state.errorMessage && contractFunction.state.errorMessage !== 'user rejected transaction') {
        addNotification({
          type: 'error',
          title: functionName,
          description: contractFunction.state.errorMessage,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractFunction.state?.errorMessage])

    return contractFunction
  }
}

export const useErc20Function = createUseContractFunction<typeof ERC20_ABI>(ERC20_INTERFACE)
export const useMultiWithdrawalControllerFunction = createUseContractFunction<typeof MULTI_WITHDRAWAL_CONTROLLER_ABI>(
  MULTI_WITHDRAWAL_CONTROLLER_INTERFACE,
)
export const useStructuredPortfolioFactoryFunction = createUseContractFunction<typeof STRUCTURED_PORTFOLIO_FACTORY_ABI>(
  STRUCTURED_PORTFOLIO_FACTORY_INTERFACE,
)

export const useStructuredAssetVaultFactoryFunction = createUseContractFunction<
  typeof STRUCTURED_ASSET_VAULT_FACTORY_ABI
>(STRUCTURED_ASSET_VAULT_FACTORY_INTERFACE)

export const useStructuredPortfolioFunction =
  createUseContractFunction<typeof STRUCTURED_PORTFOLIO_ABI>(STRUCTURED_PORTFOLIO_INTERFACE)

export const useStructuredAssetPortfolioFunction = createUseContractFunction<typeof STRUCTURED_ASSET_VAULT_ABI>(
  STRUCTURED_ASSET_VAULT_INTERFACE,
)

export const useTrancheVaultFunction = createUseContractFunction<typeof TRANCHE_VAULT_ABI>(TRANCHE_VAULT_INTERFACE)

export const useFixedInterestOnlyLoanFunction = createUseContractFunction<typeof FIXED_INTERESTED_ONLY_LOANS_ABI>(
  FIXED_INTERESTED_ONLY_LOANS_INTERFACE,
)

export const useCapitalCallsVaultFunction =
  createUseContractFunction<typeof CAPITAL_CALLS_VAULT_ABI>(CAPITAL_CALLS_VAULT_INTERFACE)
