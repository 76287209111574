import { utils } from 'ethers'

const TrancheLocator = '(address tranche, uint256 portfolioIndex, uint256 trancheIndex)'

export const CAPITAL_CALLS_VAULT_ABI = [
  `function drawDown(${TrancheLocator} trancheData) external`,
  `function deposit(${TrancheLocator} trancheData, address receiver, uint256 assets) external`,
  'function maxDeposit(address tranche, address receiver) public view returns (uint256)',
] as const

export const CAPITAL_CALLS_VAULT_INTERFACE = new utils.Interface(CAPITAL_CALLS_VAULT_ABI)
