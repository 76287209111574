import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { BorderRadiuses, Transitions } from 'styles'
import { body2, body3 } from 'components/atoms/Text'

export type MessageType = 'error' | 'info' | 'warning' | 'success' | 'default'
type Mode = 'light' | 'dark'

interface MessageProps {
  className?: string
  type?: MessageType
  textSize?: 'small' | 'medium'
  mode?: Mode
  maxWidth?: number
  wide?: boolean
}

export interface MessageComponentProps extends MessageProps {
  icon?: ReactNode
  children: string | ReactNode
}

export const Message = ({
  children,
  icon,
  className,
  type = 'default',
  textSize = 'medium',
  mode = 'light',
  maxWidth,
  wide,
}: MessageComponentProps) => (
  <MessageContainer type={type} className={className} maxWidth={maxWidth} textSize={textSize} mode={mode} wide={wide}>
    {icon}
    {children}
  </MessageContainer>
)

type StylesProps = Pick<MessageProps, 'mode'>

const errorStyles = css<StylesProps>`
  color: ${({ theme }) => theme.colors.PersianRed};
  background-color: ${({ mode, theme }) =>
    mode === 'light' ? theme.colors.MistyRose : theme.colors.DarkMessageBackgrounds.Error};
`

const infoStyles = css<StylesProps>`
  color: ${({ mode, theme }) => (mode === 'light' ? theme.colors.Elm : theme.colors.MonteCarlo)};
  background-color: ${({ mode, theme }) =>
    mode === 'light' ? theme.colors.AquaSpring : theme.colors.DarkMessageBackgrounds.Info};
`

const warningStyles = css<StylesProps>`
  color: ${({ theme }) => theme.colors.Fulvous};
  background-color: ${({ mode, theme }) =>
    mode === 'light' ? theme.colors.Antique : theme.colors.DarkMessageBackgrounds.Warning};
`

const successStyles = css<StylesProps>`
  color: ${({ theme }) => theme.colors.Emerald};
  background-color: ${({ mode, theme }) =>
    mode === 'light' ? theme.colors.Honeydew : theme.colors.DarkMessageBackgrounds.Success};
`

const defaultStyles = css<StylesProps>`
  color: ${({ mode, theme }) => (mode === 'light' ? theme.colors.Corduroy : theme.colors.Iron)};
  background-color: ${({ mode, theme }) =>
    mode === 'light' ? theme.colors.GrayNurse : theme.colors.DarkMessageBackgrounds.Default};
`

export const MessageContainer = styled.span<MessageProps>`
  display: flex;
  align-items: center;
  column-gap: 4px;
  width: ${({ wide }) => (wide ? '100%' : 'fit-content')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : '100%')};
  height: fit-content;
  padding: 6px 16px;
  border-radius: ${BorderRadiuses.s};
  font-weight: 500;
  overflow: hidden;
  transition: ${Transitions.all};

  ${({ type }) => {
    switch (type) {
      case 'default':
      default:
        return defaultStyles
      case 'success':
        return successStyles
      case 'warning':
        return warningStyles
      case 'error':
        return errorStyles
      case 'info':
        return infoStyles
    }
  }};

  ${({ textSize }) => {
    switch (textSize) {
      case 'small':
      default:
        return body3
      case 'medium':
        return body2
    }
  }};
`
