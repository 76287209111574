import { utils } from 'ethers'

export const STRUCTURED_ASSET_VAULT_FACTORY_ABI = [
  'event AssetVaultCreated(address indexed newAssetVault, address indexed manager, address[] tranches)',
  'function createAssetVault(address asset, (string, uint256, uint256, uint256) calldata assetVaultParams, (string, string, address, bytes calldata, address, bytes calldata, address, bytes calldata, uint128, uint128, uint256)[] calldata tranchesData, (uint256, uint256) calldata expectedEquityRate, bool onlyAllowedBorrowers)',
  'function getAssetVaults() public view returns (address[] memory)',
] as const

export const STRUCTURED_ASSET_VAULT_ABI = [
  'function disburse(address recipient, uint256 amount, string calldata assetReportId)',
  'function updateState(uint256 outstandingAssets, string calldata assetReportId)',
  'function repay(uint256 principalRepaid, uint256 interestRepaid, string memory assetReportId)',
  'function getTranches() view returns (address[])',
] as const

export const STRUCTURED_ASSET_VAULT_FACTORY_INTERFACE = new utils.Interface(STRUCTURED_ASSET_VAULT_FACTORY_ABI)
export const STRUCTURED_ASSET_VAULT_INTERFACE = new utils.Interface(STRUCTURED_ASSET_VAULT_ABI)
