import styled, { useTheme } from 'styled-components'
import { StatusModal, StatusModalProps, StatusModalState } from '../'
import { Separator } from 'components/atoms/Separator'
import { CopyButton } from 'components/atoms/CopyButton'
import { SupportedTransactionStatus } from 'utils/transactions'
import { useMemo } from 'react'
import { Overflows } from 'styles'
import { EtherscanLink } from 'components/atoms/EtherscanLink'
import { LinkButtonContainer } from 'components/atoms/LinkButton'
import { Token } from '@usedapp/core'
import { BigNumber } from 'ethers'
import { StatusMessage } from './StatusMessage'

export interface TransactionModalProps extends Omit<StatusModalProps, 'state' | 'width' | 'message' | 'nonCloseable'> {
  status: SupportedTransactionStatus
  successMessage?: string
  transactionHash?: string
  nonCloseableStatuses?: SupportedTransactionStatus[]
}

const defaultNonCloseableStatuses: SupportedTransactionStatus[] = ['Success', 'Pending', 'PendingSignaturePool']
const transactionStatusToStatusModalState: Record<Exclude<SupportedTransactionStatus, 'None'>, StatusModalState> = {
  Fail: 'Fail',
  Pending: 'Pending',
  PendingSignaturePool: 'Pending',
  Rejected: 'Rejected',
  Success: 'Success',
}

export const TransactionModal = ({
  transactionHash,
  successMessage,
  info,
  status,
  nonCloseableStatuses = defaultNonCloseableStatuses,
  ...props
}: TransactionModalProps) => {
  const theme = useTheme()

  const transactionInfo = useMemo(
    () => (
      <>
        {(status === 'Success' || status === 'Pending') && info}
        {(status === 'Success' || status === 'Pending') && info && transactionHash && (
          <Separator orientation="horizontal" color={theme.colors.CapeCod} />
        )}
        {transactionHash && (
          <TransactionHashRow>
            <span>Transaction hash:</span>
            <EtherscanLink addressOrHash={transactionHash} shortenOptions={{ limitBefore: 15, limitAfter: 12 }} />
            <CopyButton value={transactionHash} text="Copy address" side="bottom" />
          </TransactionHashRow>
        )}
      </>
    ),
    [info, status, theme, transactionHash],
  )

  if (status === 'None') {
    return null
  }

  return (
    <StatusModal
      {...props}
      nonCloseable={nonCloseableStatuses.includes(status)}
      state={transactionStatusToStatusModalState[status]}
      message={status === 'Success' ? successMessage : <StatusMessage status={status} />}
      info={transactionHash || info ? transactionInfo : undefined}
    />
  )
}

export const TransactionSuccessInfo = ({
  asset,
  amount,
  message,
}: {
  asset: Token
  amount: BigNumber
  message: string
}) => (
  <TransactionSuccessInfoWrapper>
    {message} <span>{asset.format(amount.toString())}</span>
  </TransactionSuccessInfoWrapper>
)

const TransactionHashRow = styled.div`
  display: grid;
  grid-template-columns: auto 270px 26px;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  ${LinkButtonContainer} {
    font-weight: 600;
    display: inline;
    color: ${({ theme }) => theme.colors.White};
    ${Overflows.FullDots};
  }
`

const TransactionSuccessInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  & > span {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.White};
  }
`
