import { shortAddress } from 'utils'
import { LinkButton } from 'components/atoms/LinkButton'
import { useEtherscanLink } from 'hooks/useEtherscanLink'
import { SupportedChainId } from 'constants/chain'

interface Props {
  className?: string
  addressOrHash: string
  shortenOptions?: { limitBefore?: number; limitAfter?: number }
  showFullAddress?: boolean
  chainId?: SupportedChainId
}

export function EtherscanLink({ className, addressOrHash, shortenOptions, showFullAddress, chainId }: Props) {
  const link = useEtherscanLink(addressOrHash, chainId)
  return (
    <LinkButton href={link} size="medium" blank noref className={className}>
      {!showFullAddress
        ? shortAddress(addressOrHash, shortenOptions?.limitBefore, shortenOptions?.limitAfter)
        : addressOrHash}
    </LinkButton>
  )
}
