import { utils } from 'ethers'

export const WITHDRAW_CONTROLLER_ABI = [
  'function MANAGER_ROLE() view returns (bytes32)',
  'function hasRole(bytes32 role, address account) view returns (bool)',
  'function floor() view returns (uint256)',
  'function previewWithdraw(uint256 assets) view returns (uint256)',
  'function withdrawAllowed(uint8) view returns (bool)',
] as const

export const MULTI_WITHDRAWAL_CONTROLLER_ABI = [
  ...WITHDRAW_CONTROLLER_ABI,
  'function initialize(address manager, uint256 floor)',
  'function multiRedeem(address portfolio, tuple[](address lender, uint8 withdrawType, uint256 shareAmount, uint256 assetAmount, uint256 fee) exceptions)',
] as const

export const MULTI_WITHDRAWAL_CONTROLLER_INTERFACE = new utils.Interface(MULTI_WITHDRAWAL_CONTROLLER_ABI)
