import { utils } from 'ethers'
import { PORTFOLIO_ABI } from '../Portfolio'

export const STRUCTURED_PORTFOLIO_ABI = [
  ...PORTFOLIO_ABI,
  'event ActiveLoanRemoved(uint256 indexed loanId)',
  'event AdminChanged(address previousAdmin, address newAdmin)',
  'event BeaconUpgraded(address indexed beacon)',
  'event CheckpointUpdated(uint256[] totalAssets, uint256[] protocolFeeRates)',
  'event Initialized(uint8 version)',
  'event LoanAdded(uint256 indexed loanId)',
  'event LoanCancelled(uint256 indexed loanId)',
  'event LoanDefaulted(uint256 indexed loanId)',
  'event LoanFunded(uint256 indexed loanId)',
  'event LoanGracePeriodUpdated(uint256 indexed loanId, uint32 newGracePeriod)',
  'event LoanRepaid(uint256 indexed loanId, uint256 amount)',
  'event Paused(address account)',
  'event PortfolioInitialized(address[] tranches)',
  'event PortfolioStatusChanged(uint8 newStatus)',
  'event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)',
  'event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)',
  'event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)',
  'event Unpaused(address account)',
  'event Upgraded(address indexed implementation)',
  'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
  'function PAUSER_ROLE() view returns (bytes32)',
  'function activeLoanIds(uint256) view returns (uint256)',
  'function addLoan(tuple(uint256 principal, uint16 periodCount, uint256 periodPayment, uint32 periodDuration, address recipient, uint32 gracePeriod, bool canBeRepaidAfterDefault) params)',
  'function calculateWaterfall() view returns (uint256[])',
  'function calculateWaterfallForTranche(uint256 trancheIdx) view returns (uint256)',
  'function calculateWaterfallForTrancheWithoutFee(uint256 trancheIdx) view returns (uint256)',
  'function calculateWaterfallWithoutFees() view returns (uint256[])',
  'function cancelLoan(uint256 loanId)',
  'function checkTranchesRatios() view',
  'function checkTranchesRatiosFromTranche(uint256 newTotalAssets) view',
  'function close()',
  'function decreaseVirtualTokenBalance(uint256 decrement)',
  'function expectedEquityRate() view returns (uint256 from, uint256 to)',
  'function fixedInterestOnlyLoans() view returns (address)',
  'function fundLoan(uint256 loanId)',
  'function getActiveLoans() view returns (uint256[])',
  'function getRoleAdmin(bytes32 role) view returns (bytes32)',
  'function getRoleMember(bytes32 role, uint256 index) view returns (address)',
  'function getRoleMemberCount(bytes32 role) view returns (uint256)',
  'function getTrancheData(uint256 i) view returns (tuple(uint128 targetApy, uint128 minSubordinateRatio, uint256 distributedAssets, uint256 maxValueOnClose, tuple(uint256 deficit, uint256 timestamp) loansDeficitCheckpoint))',
  'function getTranches() view returns (address[])',
  'function grantRole(bytes32 role, address account)',
  'function increaseVirtualTokenBalance(uint256 increment)',
  'function initialize(address manager, address underlyingToken, address _fixedInterestOnlyLoans, address _protocolConfig, tuple(string name, uint256 duration, uint256 capitalFormationPeriod, uint256 minimumSize) portfolioParams, tuple(address tranche, uint128 targetApy, uint128 minSubordinateRatio)[] tranchesInitData, tuple(uint256 from, uint256 to) _expectedEquityRate)',
  'function issuedLoanIds(uint256) view returns (bool)',
  'function loansValue() view returns (uint256)',
  'function markLoanAsDefaulted(uint256 loanId)',
  'function minimumSize() view returns (uint256)',
  'function name() view returns (string)',
  'function pause()',
  'function paused() view returns (bool)',
  'function portfolioDuration() view returns (uint256)',
  'function proxiableUUID() view returns (bytes32)',
  'function renounceRole(bytes32 role, address account)',
  'function repayLoan(uint256 loanId)',
  'function revokeRole(bytes32 role, address account)',
  'function start()',
  'function startDate() view returns (uint256)',
  'function startDeadline() view returns (uint256)',
  'function supportsInterface(bytes4 interfaceId) view returns (bool)',
  'function totalPendingFees() view returns (uint256)',
  'function tranches(uint256) view returns (address)',
  'function tranchesData(uint256) view returns (uint128 targetApy, uint128 minSubordinateRatio, uint256 distributedAssets, uint256 maxValueOnClose, tuple(uint256 deficit, uint256 timestamp) loansDeficitCheckpoint)',
  'function unpause()',
  'function updateCheckpoints()',
  'function updateLoanGracePeriod(uint256 loanId, uint32 newGracePeriod)',
  'function upgradeTo(address newImplementation)',
  'function upgradeToAndCall(address newImplementation, bytes data) payable',
] as const

export const STRUCTURED_PORTFOLIO_INTERFACE = new utils.Interface(STRUCTURED_PORTFOLIO_ABI)
