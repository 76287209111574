import { utils } from 'ethers'

export const TRANCHE_VAULT_ABI = [
  'function allowance(address, address) view returns(uint256)',
  'function approve(address, uint256) external returns(bool)',
  'function balanceOf(address) view returns(uint256)',
  'function convertToAssets(uint256 sharesAmount) view returns (uint256)',
  'function previewRedeem(uint256 shares) public view returns (uint256)',
  'function previewWithdraw(uint256 assets) public view returns (uint256)',
  'function totalAssets() view returns (uint256)',
  'function totalSupply() view returns (uint256)',
  'function maxDeposit(address) public view returns (uint256)',
  'function deposit(uint256, address) external returns (uint256)',
] as const

export const TRANCHE_VAULT_INTERFACE = new utils.Interface(TRANCHE_VAULT_ABI)
