import { utils } from 'ethers'

export const PORTFOLIO_ABI = [
  'function MANAGER_ROLE() view returns (bytes32)',
  'function asset() view returns (address)',
  'function endDate() view returns (uint256)',
  'function hasRole(bytes32 role, address account) view returns (bool)',
  'function liquidAssets() view returns (uint256)',
  'function onERC721Received(address, address, uint256, bytes) pure returns (bytes4)',
  'function protocolConfig() view returns (address)',
  'function status() view returns (uint8)',
  'function totalAssets() view returns (uint256)',
  'function virtualTokenBalance() view returns (uint256)',
] as const

export const PORTFOLIO_INTERFACE = new utils.Interface(PORTFOLIO_ABI)
