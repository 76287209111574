import styled from 'styled-components'
import { TextInline } from 'components/atoms/Text'
import { Message } from 'components/atoms/Message'
import { InfoIcon } from 'components/atoms/Icons'
import { SupportedTransactionStatus } from 'utils/transactions'

interface StatusMessageProps {
  status: Exclude<SupportedTransactionStatus, 'None'>
}

export const StatusMessage = ({ status }: StatusMessageProps) => {
  return statusMessages[status]
}

export const StatusText = styled(TextInline)`
  font-weight: 400;
`

const statusMessages: Record<Exclude<SupportedTransactionStatus, 'None'>, JSX.Element | null> = {
  Fail: <StatusText color="extra-light">Action went wrong. Please try again</StatusText>,
  Pending: <StatusText color="extra-light">It may take about 30 seconds.</StatusText>,
  PendingSignaturePool: (
    <Message type="info" mode="dark" icon={<InfoIcon />}>
      You need to have Wallet Connect connection page open for transactions to pop up. You will not receive transaction
      requests when you don’t have it open.
    </Message>
  ),
  Rejected: <StatusText color="extra-light">Transaction was rejected. Please Try Again</StatusText>,
  Success: null,
}
